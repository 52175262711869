<template>
	<a :href="shareLink" target="_blank" v-if="shareLink!=''">
		<slot></slot>
	</a>
</template>
<script type="text/javascript">
	export default {
		props: {
			/**
			* Name of the network to display.
			*/
			network: {
				type: String,
				required: true
			},
			
			/**
			* URL of the content to share.
			*/
			url: {
				type: String,
				required: true
			},
			
			/**
			* Title of the content to share.
			*/
			title: {
				type: String,
				required: true
			},

			/**
			* Description of the content to share.
			*/
			description: {
				type: String,
				default: ''
			},
			quote: {
				type: String,
				default: ''
			},
			twitterUser:{
				type: String,
				default: ''
			},
			hashtags: {
				type: String,
				default: ''
			},
			media: {
				type: String,
				default: ''
			},
			tag: {
				type: String,
				default: 'a'
			},
			popup: {
				type: Object,
				default: () => ({
					width: 626,
					height: 436
				})
			}
		},

		computed: {
			networks(){
				/* Properties:
					@u = url
					@t = title
					@d = description
					@q = quote
					@h = hashtags
					@m = media
					@tu = twitterUser
				*/
				return {
					baidu: 'http://cang.baidu.com/do/add?iu=@u&it=@t',
					buffer: 'https://bufferapp.com/add?text=@t&url=@u',
					email: 'mailto:?subject=@t&body=@u%0D%0A@d',
					evernote: 'https://www.evernote.com/clip.action?url=@u&title=@t',
					facebook: 'https://www.facebook.com/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h&display=page',
					flipboard: 'https://share.flipboard.com/bookmarklet/popout?v=2&url=@u&title=@t',
					hackernews: 'https://news.ycombinator.com/submitlink?u=@u&t=@t',
					instapaper: 'http://www.instapaper.com/edit?url=@u&title=@t&description=@d',
					line: 'http://line.me/R/msg/text/?@t%0D%0A@u%0D%0A@d',
					linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=@u',
					odnoklassniki: 'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=@u&st.comments=@t',
					pinterest: 'https://pinterest.com/pin/create/button/?url=@u&media=@m&description=@t',
					pocket: 'https://getpocket.com/save?url=@u&title=@t',
					quora: 'https://www.quora.com/share?url=@u&title=@t',
					reddit: 'https://www.reddit.com/submit?url=@u&title=@t',
					skype: 'https://web.skype.com/share?url=@t%0D%0A@u%0D%0A@d',
					sms: 'sms:?body=@t%0D%0A@u%0D%0A@d',
					stumbleupon: 'https://www.stumbleupon.com/submit?url=@u&title=@t',
					telegram: 'https://t.me/share/url?url=@u&text=@t%0D%0A@d',
					tumblr: 'https://www.tumblr.com/share/link?url=@u&name=@t&description=@d',
					twitter: 'https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu',
					viber: 'viber://forward?text=@t%0D%0A@u%0D%0A@d',
					vk: 'https://vk.com/share.php?url=@u&title=@t&description=@d&image=@m&noparse=true',
					weibo: 'http://service.weibo.com/share/share.php?url=@u&title=@t&pic=@m',
					whatsapp: 'https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d',
					wordpress: 'https://wordpress.com/press-this.php?u=@u&t=@t&s=@d&i=@m',
					xing: 'https://www.xing.com/social/share/spi?op=share&url=@u&title=@t',
					yammer: 'https://www.yammer.com/messages/new?login=true&status=@t%0D%0A@u%0D%0A@d'
				}
			},

			key(){
				return this.network.toLowerCase()
			},

			rawLink(){
				const ua = navigator.userAgent.toLowerCase()

				// On IOS, SMS sharing link need a special formatting
				// Source: https://weblog.west-wind.com/posts/2013/Oct/09/Prefilling-an-SMS-on-Mobile-Devices-with-the-sms-Uri-Scheme#Body-only
				if (this.key === 'sms' && (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)) {
					return this.networks[this.key].replace(':?', ':&')
				}

				return this.networks[this.key]
			},

			shareLink(){
				if (!Object.prototype.hasOwnProperty.call(this.networks, this.key)) {
					return ''
				}
				let link = this.rawLink

				// Twitter sharing shouldn't include empty parameter
				// Source: https://github.com/nicolasbeauvais/vue-social-sharing/issues/143
				if (this.key === 'twitter') {
					if (!this.hashtags.length) link = link.replace('&hashtags=@h', '')
					if (!this.twitterUser.length) link = link.replace('@tu', '')
				}
				
				return link
					.replace(/@tu/g, '&via=' + encodeURIComponent(this.twitterUser || ''))
					.replace(/@u/g, encodeURIComponent(this.url || ''))
					.replace(/@t/g, encodeURIComponent(this.title || ''))
					.replace(/@d/g, encodeURIComponent(this.description || ''))
					.replace(/@q/g, encodeURIComponent(this.quote || ''))
					.replace(/@h/g, this.encodedHashtags|| '')
					.replace(/@m/g, encodeURIComponent(this.media || ''))
			},
			encodedHashtags () {
				if (this.key === 'facebook' && this.hashtags.length) {
					return '%23' + this.hashtags.split(',')[0]
				}

				return this.hashtags
			}
		}
	}
</script>